exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-case-category-consulting-tsx": () => import("./../../../src/pages/case/category/consulting.tsx" /* webpackChunkName: "component---src-pages-case-category-consulting-tsx" */),
  "component---src-pages-case-category-development-tsx": () => import("./../../../src/pages/case/category/development.tsx" /* webpackChunkName: "component---src-pages-case-category-development-tsx" */),
  "component---src-pages-case-category-index-tsx": () => import("./../../../src/pages/case/category/index.tsx" /* webpackChunkName: "component---src-pages-case-category-index-tsx" */),
  "component---src-pages-case-category-migration-tsx": () => import("./../../../src/pages/case/category/migration.tsx" /* webpackChunkName: "component---src-pages-case-category-migration-tsx" */),
  "component---src-pages-case-category-newbusiness-tsx": () => import("./../../../src/pages/case/category/newbusiness.tsx" /* webpackChunkName: "component---src-pages-case-category-newbusiness-tsx" */),
  "component---src-pages-case-category-operationmonitoring-tsx": () => import("./../../../src/pages/case/category/operationmonitoring.tsx" /* webpackChunkName: "component---src-pages-case-category-operationmonitoring-tsx" */),
  "component---src-pages-case-category-serverlessarchitecture-tsx": () => import("./../../../src/pages/case/category/serverlessarchitecture.tsx" /* webpackChunkName: "component---src-pages-case-category-serverlessarchitecture-tsx" */),
  "component---src-pages-case-colorsmagazine-tsx": () => import("./../../../src/pages/case/colorsmagazine.tsx" /* webpackChunkName: "component---src-pages-case-colorsmagazine-tsx" */),
  "component---src-pages-case-dive-tsx": () => import("./../../../src/pages/case/dive.tsx" /* webpackChunkName: "component---src-pages-case-dive-tsx" */),
  "component---src-pages-case-idom-tsx": () => import("./../../../src/pages/case/idom.tsx" /* webpackChunkName: "component---src-pages-case-idom-tsx" */),
  "component---src-pages-case-index-tsx": () => import("./../../../src/pages/case/index.tsx" /* webpackChunkName: "component---src-pages-case-index-tsx" */),
  "component---src-pages-case-kabaya-centos-to-al-2023-migration-tsx": () => import("./../../../src/pages/case/kabaya-centos-to-al2023-migration.tsx" /* webpackChunkName: "component---src-pages-case-kabaya-centos-to-al-2023-migration-tsx" */),
  "component---src-pages-case-kabaya-tsx": () => import("./../../../src/pages/case/kabaya.tsx" /* webpackChunkName: "component---src-pages-case-kabaya-tsx" */),
  "component---src-pages-case-mikuru-tsx": () => import("./../../../src/pages/case/mikuru.tsx" /* webpackChunkName: "component---src-pages-case-mikuru-tsx" */),
  "component---src-pages-case-transtructure-tsx": () => import("./../../../src/pages/case/transtructure.tsx" /* webpackChunkName: "component---src-pages-case-transtructure-tsx" */),
  "component---src-pages-case-unifinity-tsx": () => import("./../../../src/pages/case/unifinity.tsx" /* webpackChunkName: "component---src-pages-case-unifinity-tsx" */),
  "component---src-pages-cloudintroduction-tsx": () => import("./../../../src/pages/cloudintroduction.tsx" /* webpackChunkName: "component---src-pages-cloudintroduction-tsx" */),
  "component---src-pages-company-features-tsx": () => import("./../../../src/pages/company/features.tsx" /* webpackChunkName: "component---src-pages-company-features-tsx" */),
  "component---src-pages-company-human-tsx": () => import("./../../../src/pages/company/human.tsx" /* webpackChunkName: "component---src-pages-company-human-tsx" */),
  "component---src-pages-company-index-tsx": () => import("./../../../src/pages/company/index.tsx" /* webpackChunkName: "component---src-pages-company-index-tsx" */),
  "component---src-pages-dws-tsx": () => import("./../../../src/pages/dws.tsx" /* webpackChunkName: "component---src-pages-dws-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inquiry-complete-tsx": () => import("./../../../src/pages/inquiry/complete.tsx" /* webpackChunkName: "component---src-pages-inquiry-complete-tsx" */),
  "component---src-pages-inquiry-error-tsx": () => import("./../../../src/pages/inquiry/error.tsx" /* webpackChunkName: "component---src-pages-inquiry-error-tsx" */),
  "component---src-pages-inquiry-index-tsx": () => import("./../../../src/pages/inquiry/index.tsx" /* webpackChunkName: "component---src-pages-inquiry-index-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-service-amazonconnect-tsx": () => import("./../../../src/pages/service/amazonconnect.tsx" /* webpackChunkName: "component---src-pages-service-amazonconnect-tsx" */),
  "component---src-pages-service-consulting-tsx": () => import("./../../../src/pages/service/consulting.tsx" /* webpackChunkName: "component---src-pages-service-consulting-tsx" */),
  "component---src-pages-service-development-tsx": () => import("./../../../src/pages/service/development.tsx" /* webpackChunkName: "component---src-pages-service-development-tsx" */),
  "component---src-pages-service-devops-tsx": () => import("./../../../src/pages/service/devops.tsx" /* webpackChunkName: "component---src-pages-service-devops-tsx" */),
  "component---src-pages-service-devsecops-tsx": () => import("./../../../src/pages/service/devsecops.tsx" /* webpackChunkName: "component---src-pages-service-devsecops-tsx" */),
  "component---src-pages-service-dockercontainer-tsx": () => import("./../../../src/pages/service/dockercontainer.tsx" /* webpackChunkName: "component---src-pages-service-dockercontainer-tsx" */),
  "component---src-pages-service-index-tsx": () => import("./../../../src/pages/service/index.tsx" /* webpackChunkName: "component---src-pages-service-index-tsx" */),
  "component---src-pages-service-legacysystem-tsx": () => import("./../../../src/pages/service/legacysystem.tsx" /* webpackChunkName: "component---src-pages-service-legacysystem-tsx" */),
  "component---src-pages-service-migration-tsx": () => import("./../../../src/pages/service/migration.tsx" /* webpackChunkName: "component---src-pages-service-migration-tsx" */),
  "component---src-pages-service-newbusiness-tsx": () => import("./../../../src/pages/service/newbusiness.tsx" /* webpackChunkName: "component---src-pages-service-newbusiness-tsx" */),
  "component---src-pages-service-operationmonitoring-tsx": () => import("./../../../src/pages/service/operationmonitoring.tsx" /* webpackChunkName: "component---src-pages-service-operationmonitoring-tsx" */),
  "component---src-pages-service-serverlessarchitecture-tsx": () => import("./../../../src/pages/service/serverlessarchitecture.tsx" /* webpackChunkName: "component---src-pages-service-serverlessarchitecture-tsx" */),
  "component---src-pages-service-static-wordpress-tsx": () => import("./../../../src/pages/service/static-wordpress.tsx" /* webpackChunkName: "component---src-pages-service-static-wordpress-tsx" */),
  "component---src-pages-service-tcooptimization-tsx": () => import("./../../../src/pages/service/tcooptimization.tsx" /* webpackChunkName: "component---src-pages-service-tcooptimization-tsx" */),
  "component---src-pages-service-virtualdesktopinfrastructure-tsx": () => import("./../../../src/pages/service/virtualdesktopinfrastructure.tsx" /* webpackChunkName: "component---src-pages-service-virtualdesktopinfrastructure-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */)
}

